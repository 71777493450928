import React, {useEffect, useState} from 'react';
import './Contus.css';
import Footer from './Footer';
import Nav from './Nav';
import emailjs from "emailjs-com";

const Contus = () => {
    const [name, setName] = useState('');
    const [subject, setSubject] = useState('');
    const [fromMail, setFromMail] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
  useEffect(()=>{
    window.scrollTo(0,0);
    // return()=>window.removeEventListener()
  },[])
    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus('Sending...');

        try{
            const response = await emailjs.send(
                "service_a2nr37o",
                "template_97y9nwe",
                {
                    to_name: "HR@Quantumleap",
                    from_name: name,
                    message: message,
                    reply_to: fromMail
                },
                "W_3pHjxHZSxXbFqZo"
            );
            setStatus('Message sent successfully');
            console.log('Success!!!', response.status, response.text)
        } catch (e) {
            console.error('Error sending email:', e);
            setStatus('Failed to send message.');
        }
    };
  return (
    <div>
    <Nav></Nav>
    <div className='head'> <h1>Contact Us</h1> </div>
    <div className='contact-main'>
            <div className='c-left'>
            <div className="contact-us-container">
           
            <h2>We're Here to Help</h2>
            <p>
            Whether you're looking for IT staffing solutions, seeking consultancy services, or just have a question, we’re here to assist you. Reach out to us through any of the following methods, and our team will get back to you promptly.
            </p>
            
            <h3>Get in Touch</h3>
            <div className="contact-methods">
            <div className="contact-item">
                <strong>Phone: +1-8326190532</strong>
                <p>QuantumLeap IT Solutions</p>
                {/*<p>Available Monday to Friday, 9:00 AM to 6:00 PM</p>*/}
            </div>
            
            <div className="contact-item">
                <strong>Email:</strong>
                <p>
                <a href="mailto:hr@quantumleapits.com">hr@quantumleapits.com</a>
                </p>
                <p>We strive to respond to all inquiries within 24 hours.</p>
            </div>
            </div>

            {/*<h3>Business Hours:</h3>*/}
            {/*<div className="business-hours">*/}
            {/*<p><strong>Monday to Friday:</strong> 9:00 AM - 6:00 PM</p>*/}
            {/*<p><strong>Saturday & Sunday:</strong> Closed</p>*/}
            {/*</div>*/}

            <h3>Request a Consultation</h3>
            <p>
            Ready to take the next step? Fill out the form below, and one of our consultants will reach out to you to discuss how we can help your business succeed.
            </p>
        </div>
    
      
  </div>
    <div className='c-right'>
    <div className="c-t">
        <form onSubmit={handleSubmit}>
            <label>
                Name:
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} required/>
            </label>
            <label>
                Subject:
                <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} required/>
            </label>
            <label>
                Email:
                <input type="email" value={fromMail} onChange={(e) => setFromMail(e.target.value)} required/>
            </label>
            <label>
                Message:
                <textarea rows="5" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
            </label>
            <button type="submit" className='btn5'>Submit</button>
        </form>
        <p>{status}</p>
    </div>
    </div>
    </div>
        <Footer></Footer>
    </div>
  );
};

export default Contus;
